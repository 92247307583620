<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <!-- <el-form-item label="归属公司">
              <el-select placeholder="归属公司" v-model="formInline.client_id">
                <el-option label="山东联通" value="shandongliantong"></el-option>
                <el-option label="北京联通" value="beijingliantong"></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item :label="$t('index.device_type')" prop="type">
              <el-select v-model="formInline.type" :placeholder="$t('index.device_type')" clearable>
                <el-option
                    v-for="item in deviceType"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="onSearch" type="primary">{{ $t('device.inquire') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-card>
        <ul class="statdata">
          <li>
            <p>
              {{ userStat.day_user }}
              <span
                  :class="{'up': userStat.pre_user>=0, 'down': userStat.pre_user<0}"
              >
                <i
                    :class="{'el-icon-top': userStat.pre_user>=0, 'el-icon-bottom': userStat.pre_user<0}"
                ></i>
                {{ userStat.pre_user }}
              </span>
            </p>
            <h6>{{ $t('stat.user') }}</h6>
          </li>
          <li>
            <p>
              {{ userStat.day_device }}
              <span
                  :class="{'up': userStat.pre_device>=0, 'down': userStat.pre_device<0}"
              >
                <i
                    :class="{'el-icon-top': userStat.pre_device>=0, 'el-icon-bottom': userStat.pre_device<0}"
                ></i>
                {{ userStat.pre_device }}
              </span>
            </p>
            <h6>{{ $t('index.device_total') }}</h6>
          </li>
          <li>
            <p>
              {{ userStat.day_client }}
              <span
                  :class="{'up': userStat.pre_client>=0, 'down': userStat.pre_client<0}"
              >
                <i
                    :class="{'el-icon-top': userStat.pre_client>=0, 'el-icon-bottom': userStat.pre_client<0}"
                ></i>
                {{ userStat.pre_client }}
              </span>
            </p>
            <h6>{{ $t('stat.customer') }}</h6>
          </li>
          <li>
            <p>
              {{ userStat.day_manage }}
              <span
                  :class="{'up': userStat.pre_manage>=0, 'down': userStat.pre_manage<0}"
              >
                <i
                    :class="{'el-icon-top': userStat.pre_manage>=0, 'el-icon-bottom': userStat.pre_manage<0}"
                ></i>
                {{ userStat.pre_manage }}
              </span>
            </p>
            <h6>{{ $t('stat.register') }}</h6>
          </li>
        </ul>
      </el-card>
      <div class="middlecont">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card chartcard">
                <div
                    :style="{width: '100%', height: '300px'}"
                    class="equipsumChart chart"
                    ref="equipsumChart"
                ></div>
              </el-card>
              <el-card class="box-card chartcard">
                <div :style="{width: '100%', height: '300px'}" class="dialplate" ref="dialplate"></div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple">
              <el-card class="box-card mapcard">
                <div :style="{width: '100%', height: '600px'}" class="map" ref="map"></div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="bottomcont">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card chartcard">
                <div :style="{width: '100%', height: '300px'}" class="abnormal" ref="abnormal"></div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card chartcard">
                <div :style="{width: '100%', height: '300px'}" class="newtrend" ref="newtrend"></div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card chartcard">
                <div :style="{width: '100%', height: '300px'}" class="sexstat" ref="sexstat"></div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="lastcont">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card tablecard">
                <h4>{{ $t('alarm.temperature_alarm') }}</h4>
                <p>{{ $t('stat.alarm') }}：{{ tempCount + $t('stat.number') }}</p>
                <el-table :data="temptable">
                  <el-table-column :label="$t('index.device')" prop="imei"></el-table-column>
                  <el-table-column :label="$t('detail.temp')" prop="content" width="100px"></el-table-column>
                  <el-table-column :label="$t('index.device_name')" prop="name" width="80px"></el-table-column>
                  <el-table-column :label="$t('index.alarm_time')" prop="create_at"></el-table-column>
                </el-table>
                <p>
                  <a @click="toHeartAlarm">{{ $t('index.more') }}...</a>
                </p>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card tablecard">
                <h4>{{ $t('alarm.sos_alarm') }}</h4>
                <p>{{ $t('stat.alarm') }}：{{ sosCount + $t('stat.number') }}</p>
                <el-table :data="sostable">
                  <el-table-column :label="$t('index.device')" prop="imei"></el-table-column>
                  <el-table-column :label="$t('index.device_name')" prop="name" width="80px"></el-table-column>
                  <el-table-column :label="$t('index.alarm_time')" prop="create_at"></el-table-column>
                </el-table>
                <p>
                  <a @click="toSosAlarm">{{ $t('index.more') }}...</a>
                </p>
              </el-card>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content bg-purple">
              <el-card class="box-card tablecard">
                <h4>{{ $t('fence.alarm') }}</h4>
                <p>{{ $t('stat.alarm') }}：{{ fenceCount + $t('stat.number') }}</p>
                <el-table :data="fencetable">
                  <el-table-column :label="$t('index.device')" prop="imei"></el-table-column>
                  <el-table-column :label="$t('index.device_name')" prop="name" width="80px"></el-table-column>
                  <el-table-column :label="$t('index.alarm_time')" prop="create_at"></el-table-column>
                </el-table>
                <p>
                  <a @click="toFenceAlarm">{{ $t('index.more') }}...</a>
                </p>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import echarts from "../../utils/echarts";
import {VisualMapComponent} from "echarts/components";
import {MapChart, ScatterChart} from "echarts/charts";
import china from "../../assets/js/china.json";
import data from "../../model/data";
import device from "../../model/device";

echarts.use([ScatterChart]);
echarts.use([VisualMapComponent]);
echarts.use([MapChart]);
export default {
  name: "stat",
  data() {
    return {
      formInline: {
        // client_id: "",
        type: ""
      },
      userStat: {},
      temptable: [],
      tempCount: 0,
      sostable: [],
      sosCount: 0,
      fencetable: [],
      fenceCount: 0,
      deviceMap: [],
      deviceType: []
    };
  },
  mounted() {
    this.deviceTypeArr();
    this.loadPanel();
    this.loadPanelUser();
    this.locationMap();
    this.loadPanelStat();
    this.loadPanelAlarm();
  },
  methods: {
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async loadPanel() {
      const ret = await data.panel(this.formInline);
      if (ret.code === 200) {
        this.equipsumFun(ret.data);
        this.onlinepropFun(ret.data);
      }
    },
    async loadPanelUser() {
      const ret = await data.user({});
      if (ret.code === 200) {
        this.userStat = ret.data;
      }
    },
    async loadPanelStat() {
      const ret = await data.panelStat(this.formInline);
      if (ret.code === 200) {
        this.abnormalFun(ret.data.alarm);
        this.newtrendFun(ret.data.user_date, ret.data.user_list);
        this.sexstatFun(
            ret.data.gender_date,
            ret.data.gender_man,
            ret.data.gender_woman
        );
      }
    },
    async loadPanelAlarm() {
      const ret = await data.panelAlarm(this.formInline);
      if (ret.code === 200) {
        this.temptable = ret.data.temp;
        this.tempCount = ret.data.temp_count;
        this.sostable = ret.data.sos;
        this.sosCount = ret.data.sos_count;
        this.fencetable = ret.data.fence;
        this.fenceCount = ret.data.fence_count;
      }
    },
    async locationMap() {
      const ret = await device.map();
      if (ret.code === 200) {
        this.mapFun(ret.data);
      }
    },
    // mapFun (data),
    equipsumFun(data) {
      let myEchart = echarts.init(this.$refs.equipsumChart);
      var option = {
        title: {
          text: this.$t('index.device_stat'),
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}:{d}%"
        },
        legend: {
          x: "center",
          bottom: 15 + "%",
          data: [this.$t('index.offline'), this.$t('index.new_add_today'), this.$t('index.online')]
        },
        series: [
          {
            name: this.$t('stat.referrer'),
            type: "pie",
            radius: ["35%", "55%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "22",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: data.outline,
                name: this.$t('index.offline'),
                itemStyle: {color: "#e85268"}
              },
              {
                value: data.day,
                name: this.$t('index.new_add_today'),
                itemStyle: {color: "#50c1e9"}
              },
              {
                value: data.online,
                name: this.$t('index.online'),
                itemStyle: {color: "#a7e854"}
              }
            ]
          }
        ]
      };
      myEchart.setOption(option);
    },
    onlinepropFun(data) {
      let myEchart = echarts.init(this.$refs.dialplate);
      var colorTemplate1 = [
        [0.2, "#50c1e9"],
        [0.8, "#50c1e9"],
        [1, "#50c1e9"]
      ];
      var option = {
        title: {
          text: this.$t('stat.rate'),
          left: "center"
        },
        tooltip: {
          formatter: "{a} <br/>{b} : {c}%"
        },
        series: [
          {
            name: this.$t('stat.rate'),
            type: "gauge",
            detail: {
              formatter: "{value}%",
              textStyle: {
                color: "#333333",
                fontSize: 22,
                offsetCenter: [0, "60%"]
              }
            }, //仪表盘显示数据
            radius: "76%",
            center: ["50%", "50%"],
            axisLine: {
              //仪表盘轴线样式
              lineStyle: {
                color: colorTemplate1,
                width: 10
              }
            },
            splitLine: {
              //分割线样式
              length: 10
            },
            pointer: {
              width: 5, //指针的宽度
              length: "65%" //指针长度，按照半圆半径的百分比
            },
            data: [{value: data.rate, name: this.$t('stat.rate') + "（%）"}],
            markPoint: {
              symbol: "circle",
              symbolSize: 5,
              data: [
                {x: "center", y: "center", itemStyle: {color: "#333333"}}
              ]
            }
          }
        ]
      };
      myEchart.setOption(option);
    },
    abnormalFun(data) {
      const alarm = [];
      data.forEach(v => {
        alarm.push({
          value: v.cnt,
          name: v.type
        });
      });
      let myEchart = echarts.init(this.$refs.abnormal);
      var option = {
        title: {
          text: this.$t('stat.anomaly'),
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        series: [
          {
            name: this.$t('stat.anomaly'),
            type: "pie",
            radius: [0, 100],
            center: ["50%", "50%"],
            roseType: "area",
            data: alarm
          }
        ]
      };
      myEchart.setOption(option);
    },
    newtrendFun(date, data) {
      let myEchart = echarts.init(this.$refs.newtrend);
      var option = {
        title: {
          text: this.$t('stat.growth'),
          left: "center"
        },
        tooltip: {
          trigger: "axis"
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: date
          }
        ],
        yAxis: [
          {
            type: "value",
            splitNumber: 1,
            interval: 5, //每次增加几个
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed"
              }
            }
          }
        ],
        series: [
          {
            name: this.$t('health.frequency'),
            type: "line",
            data: data,
            markPoint: {
              data: [
                {type: "max", name: this.$t('group.max')},
                {type: "min", name: this.$t('group.min')}
              ]
            },
            itemStyle: {
              normal: {
                color: "#50c1e9", //折线点的颜色
                lineStyle: {
                  color: "#50c1e9" //折线的颜色
                }
              }
            }
          }
        ]
      };
      myEchart.setOption(option);
    },
    sexstatFun(date, man, woman) {
      let myEchart = echarts.init(this.$refs.sexstat);
      var option = {
        title: {
          text: this.$t('stat.gender'),
          left: "center"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999999"
            }
          }
        },
        color: ["#6395f9", "#64daab"],
        legend: {
          right: 10,
          top: 10 + "%",
          data: [this.$t('user.male'), this.$t('user.female')]
        },
        xAxis: [
          {
            type: "category",
            data: date,
            axisPointer: {
              type: "shadow"
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: this.$t('stat.number'),
            min: 0,
            interval: 10,
            axisLabel: {
              formatter: "{value}"
            }
          }
        ],
        series: [
          {
            name: this.$t('user.male'),
            type: "bar",
            data: man
          },
          {
            name: this.$t('user.female'),
            type: "bar",
            data: woman
          }
        ]
      };

      myEchart.setOption(option);
    },
    async mapFun(data) {
      const locationData = [];
      data.list.forEach(v => {
        locationData.push({
          value: [v.longitude, v.latitude, v.is_online],
          name: v.imei
        });
      });
      const myChart = echarts.init(this.$refs.map);
      let option = {};
      myChart.hideLoading();

      echarts.registerMap("china", china);

      myChart.setOption(
          (option = {
            geo: {
              map: "china",
              roam: true,
              itemStyle: {
                emphasis: {
                  areaColor: "#dbd9d7"
                }
              }
            },
            tooltip: {
              trigger: "item"
            },

            series: [
              {
                name: this.$t('detail.location'),
                type: "scatter",
                coordinateSystem: "geo",
                showLegendSymbol: true,
                data: locationData,
                symbol: "pin",
                symbolSize: 30,
                itemStyle: {
                  color: function (d) {
                    if (d.data.value[2] == 0) {
                      return "red";
                    } else {
                      return "green";
                    }
                  },
                  borderColor: "#000000",
                  borderWidth: 0,
                  borderType: "solid"
                }
              }
            ]
          })
      );
      option && myChart.setOption(option);
    },
    onSearch() {
      this.deviceTypeArr();
      this.loadPanel();
      this.loadPanelUser();
      this.locationMap();
      this.loadPanelStat();
      this.loadPanelAlarm();
    },
    toHeartAlarm() {
      this.$router.push({
        path: "/alarm/home",
        query: {
          type: 5
        }
      });
    },
    toSosAlarm() {
      this.$router.push({
        path: "/alarm/home",
        query: {
          type: 3
        }
      });
    },
    toFenceAlarm() {
      this.$router.push({
        path: "/alarm/home",
        query: {
          type: 1
        }
      });
    }
  }
};
</script>
<style scoped>
.up {
  color: red;
}

.down {
  color: green;
}

.statdata {
  display: flex;
  align-items: center;
}

.statdata li {
  border-right: 2px solid #ececec;
  width: 25%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statdata li:last-child {
  border-right: none;
}

.statdata li p {
  font-size: 34px;
  margin-top: 15px;
}

.statdata li p span {
  font-size: 16px;
}

.statdata li h6 {
  font-size: 18px;
  color: #929292;
  margin-top: 14px;
}

.chartcard {
  height: 300px;
  margin-top: 20px;
}

.mapcard {
  height: 620px;
  margin-top: 20px;
}

.tablecard {
  margin-top: 20px;
}

.tablecard h4 {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.tablecard p {
  text-align: right;
  margin-right: 20px;
  font-size: 14px;
}

.tablecard p a {
  text-decoration: none;
  color: #4bacec;
  display: block;
  margin: 10px 0px;
  cursor: pointer;
}
</style>
